import React from 'react'
import { Link, graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hat from '../components/hat';
import Story from '../components/story';
import Recipes from '../components/recipes';
import Stores from '../components/stores';

const propTypes = {
  data: PropTypes.object.isRequired,
}

class RecipeTemplate extends React.Component {
  render() {
    const recipe = this.props.data.contentfulRecipe
    const {
      title,
      body,
      image,
    } = recipe
    return (
      <Layout>
        <SEO title='Recepten' keywords={[`gatsby`, `application`, `react`]} />
        <div className='recipe'>
          <div className="recipeContent">
            <h1>{title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="recipeImage">
            {!!image && <Img {...image} />}
          </div>
        </div>
        {/*<div className='moreRecipes'>
          <h2>Gerechten met deze sambal</h2>
          <p>[todo]</p>
          <p>Zelf een lekker recept op idee? Deel het via social media en gebruik #spicybusiness.</p>
        </div>*/}
        <Hat />
        <Story />
        <Hat />
        <Stores />
      </Layout>
    )
  }
}

RecipeTemplate.propTypes = propTypes

export default RecipeTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulRecipe(id: { eq: $id }) {
      id
      slug
      title
      image {
        id
        title
        fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
